import m from 'mithril'
import {
    Dialog,
} from 'components'
import AnswerDialog from './dialog'

class EventDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.orders = ['A', 'B', 'C', 'D']
        this.select = false
        this.check = false
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(event)
            m.redraw()
        }, 200)
    }

    view(vnode) {
        return m('.modal-dialog.m-0', {
            style: {
                height: "100vh",
                minWidth: "100%",
                maxWidth: "100%",
            }
        }, [
            m(".modal-content.border-0.rounded-0", {
                style: {
                    height: "100vh"
                }
            }, [
                m(".row.no-gutters.w-100", [
                    m(".col-md-12.py-3", {
                        style: {
                            background: this.model.color
                        }
                    }, [
                        m(".container", [
                            m(".d-flex.justify-content-between", [
                                m("img.img-fluid", {
                                    src: `./img/logo2.png`,
                                    style:{
                                        maxWidth: "250px",
                                        maxHeight: "80px"
                                    }
                                }),
                            ])
                        ])

                    ]),
                    m(".col-md-12", [
                        m(".container", [
                            m(".row.no-gutters.w-100", [
                                m(".col-md-12.pt-5.pb-1.pl-3", [
                                    m("span.font-weight-bold", {
                                        style: {
                                            color: this.model.color,
                                            fontSize: "5rem",
                                            lineHeight: "4.25rem",
                                            fontWeight: 700
                                        }
                                    }, this.model.order),
                                ]),
                                m(".col-md-12.py-2.pl-3", [
                                    m("span.font-weight-bold.pb-1", {
                                        style: {
                                            color: this.model.color,
                                            fontSize: "2rem",
                                            lineHeight: "4.25rem",
                                            borderBottom: "5px " + this.model.color + " solid",
                                            fontWeight: 700
                                        }
                                    }, this.model.question),
                                ]),
                                (this.model.hasOwnProperty("description")) ? [
                                    m(".col-md-12.py-2.pl-3", [
                                        m("span.font-weight-bold.pb-1", {
                                            style: {
                                                color: this.model.color,
                                                fontSize: "1.5rem",
                                                lineHeight: "3.25rem",
                                                // borderBottom: "5px " + this.model.color + " solid",
                                                fontWeight: 700
                                            }
                                        }, this.model.description),
                                    ]),
                                ] : [],
                                this.model.items.map((_item, index) => {
                                    return [
                                        m(".col-md-9.py-1", [
                                            m(".row.no-gutters.w-100.pl-3", {
                                                style: {
                                                    // background: (this.model.answer == index) ? [(this.select) ? this.model.color : "#FFFFFF"] : "#FFFFFF"
                                                    background:
                                                        (this.check) ? [(this.model.answer === index) ? "#8DEB86" : [(this.select === index) ? "#F58E8E" : "#FFFFFF"]] : [(this.select === index) ? this.model.color : "#FFFFFF"]
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    if (!this.check) {
                                                        this.select = index
                                                    }
                                                    console.log(this.select)

                                                }
                                            }, [
                                                m(".col-1", [
                                                    m("span.font-weight-bold", {
                                                        style: {
                                                            color: (this.select === index || (this.check && this.model.answer === index)) ? "#FFFFFF" : this.model.color,
                                                            fontSize: "5rem",
                                                            fontWeight: 700
                                                        }
                                                    }, this.orders[index]),
                                                ]),
                                                m(".col-11.d-flex.align-items-center", [
                                                    m("p.m-0", {
                                                        style: {
                                                            color: (this.select === index || (this.check && this.model.answer === index)) ? "#FFFFFF" : "#464646",
                                                            fontSize: "1.75rem",
                                                        }
                                                    }, _item),
                                                ]),
                                            ])
                                        ])
                                    ]
                                })

                            ]),

                            m(".col-12.py-5", [
                                m(".d-flex.align-items-center.justify-content-center", {
                                    style: {
                                        background: (this.select === false) ? "#FFFFFF" : this.model.color,
                                        color: (this.select === false) ? this.model.color : "#FFFFFF",
                                        border: "1px " + this.model.color + " solid",
                                        borderRadius: "30px",
                                        height: "87px",
                                        cursor: "pointer",
                                        width: "280px"
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        if (this.select !== false) {
                                            vnode.attrs.model.isFinish = true
                                            this.check = true

                                            Dialog.show(AnswerDialog, {
                                                attrs: {
                                                    answer: (this.select === this.model.answer)? true : false,
                                                    model: vnode.attrs.model
                                                },
                                                didHide: (returnValue) => {
                                                    this.close(true)
                                                }
                                            })

                                            
                                        }

                                    }
                                }, [
                                    m("span.font-weight-bold.px-2", { style: { fontSize: "2.5rem", fontWeight: 700 } }, (this.select === false) ? "請先選擇答案" : "看答案")
                                ])
                            ]),

                        ])
                    ]),


                ])
            ]),

        ])
    }
}

export default EventDialog