import m from 'mithril'
import stream from 'mithril/stream'
import {
    Dialog,
} from 'components'

export default class EditDialog {
    constructor(vnode) {
        this.answer = vnode.attrs.answer
        this.model = vnode.attrs.model
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    oninit(state) {
        const audio = document.createElement("audio");
        if (this.answer){
            audio.src = "./mp3/correct.mp3";
            setTimeout(() => {
                audio.play();
            }, 50)
        }else{
            audio.src = "./mp3/wrong.mp3";
                audio.play();
        }
        // setTimeout(() => {
        //     audio.play();
        // }, 50)
    }

    view() {
        return [m(".modal-dialog.modal-dialog-centered.modal-lg", [
            m('.modal-content', [
                m('.modal-body', [
                    m(".row.no-gutters.d-flex.align-items-center.justify-content-center", [
                        m(".col-12.py-2.text-center", [
                            m("img.img-fluid", {
                                src: (this.answer) ? `./img/01.png` : `./img/02.png`,
                                style:{
                                    maxWidth: "200px",
                                    // maxHeight: "80px"
                                }
                            }),
                        ]),
                        m(".col-12.text-center", [
                            m("span.font-weight-bold", {
                                style: {
                                    color: (this.answer) ? this.model.color :"#E36642",
                                    fontSize: "4rem",
                                    fontWeight: 700
                                }
                            }, (this.answer) ? '恭喜答對囉！' : "答案錯了喔！"),
                        ]),
                        m(".col-12.py-4.d-flex.align-items-center.justify-content-center", [
                            m(".d-flex.align-items-center.justify-content-center", {
                                style: {
                                    background: "#FFFFFF",
                                    color: this.model.color,
                                    border: "1px " + this.model.color + " solid",
                                    borderRadius: "30px",
                                    height: "65px",
                                    cursor: "pointer",
                                    width: "200px"
                                },
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close(true)
                                }
                            }, [
                                m("span.font-weight-bold.px-2", { style: { fontSize: "2rem", fontWeight: 700 } }, "回列表")
                            ])
                        ]),
                    ])
                ]),

            ])
        ])]
    }
}