import m from 'mithril'
import classNames from 'classnames'
import {
    Dialog
} from 'components'
import QuestionDialog from './dialogs/question'

class DashboardPage {
    constructor() {
        this.questions = [
            {
                order: "01", color: '#9ecce8', answer: 1, isFinish: false, question: "間質性肺病的英文縮寫是?",
                items: ["ICD", "ILD","ISD", "ICD"]
            },
            {
                order: "02", color: '#79c2f2', answer: 0, isFinish: false,
                question: "間質性肺病(ILD)患者肺功能會逐漸惡化，生活品質受影響，亦增加急性惡化及死亡風險。",
                items: ["是", "否"]
            },
            {
                order: "03", color: '#6dadc6', answer: 2, isFinish: false,
                question: "特發性肺纖維化(IPF)又稱?",
                items: ["絲瓜肺", "胡瓜肺", "菜瓜布肺","蜂巢肺"]
            },
            {
                order: "04", color: '#79c2f2', answer: 2, isFinish: false,
                question: "間質性肺病(ILD)中有2-3成的病人會發展成哪種疾病?",
                items: ["全身性硬化症有關之間質性肺病", "全身性紅斑性狼瘡間質性肺病", "漸進性纖維化間質性肺病(PF-ILD)", "特發性非特異性間質性肺炎"]
            },
            {
                order: "05", color: '#79c2f2', answer: 0, isFinish: false,
                question: "漸進性纖維化間質性肺病(PF-ILD) 與特發性肺纖維化 (IPF) 類似，疾病不可逆且預後不佳?",
                items: ["是", "否"]
            },
            {
                order: "06", color: '#2ca6e0', answer: 0, isFinish: false,
                question: "特發性肺纖維化(IPF)是致命的肺部疾病，平均存活期約多少年?",
                items: ["2-5年", "7-10年", "15-18年", "20-22年"]
            },
            {
                order: "07", color: '#79c2f2', answer: 1, isFinish: false,
                question: "下列何者不是特發性肺纖維化(IPF)的高危險群?",
                items: ["有吸菸或曾吸菸者", "女性", "長期暴露在危險因子環境中","50歲以上老年人"]
            },
            {
                order: "08", color: '#2ca6e0', answer: 3, isFinish: false,
                question: "特發性肺纖維化(IPF)的病患，有可能出現什麼特徵?",
                items: ["濃痰", "體重上升", "視力衰退", "杵狀指"]
            },
            {
                order: "09", color: '#6dadc6', answer: 0, isFinish: false,
                question: "下列何者不是特發性肺纖維化(IPF) 的非藥物治療項目?",
                items: ["健走", "肺復原", "氧氣治療", "肺移植"]
            },
            {
                order: "10", color: '#9ecce8', answer: 0, isFinish: false,
                question: "特發性肺纖維化(IPF)需及早介入治療，可減緩疾病進程?",
                items: ["是", "否"]
            },
            {
                order: "11", color: '#79c2f2', answer: 1, isFinish: false,
                question: "抗肺纖維化藥物Nintedanib的商品名為?",
                items: ["OFFV", "OFEV", "OOFV", "OFEE"]
            },
            {
                order: "12", color: '#79c2f2', answer: 1, isFinish: false,
                question: "抗肺纖維化藥物OFEV(Nintedanib)不需事前審查就可開立處方使用?",
                items: ["是", "否"]
            },
            {
                order: "13", color: '#1884c6', answer: 0, isFinish: false,
                question: "抗肺纖維化藥物健保給付規定，經醫師確診為IPF病人，用力肺活量(FVC)要在?", 
                items: ["50%以上", "30%-50%", "60%-80%", "40%-60%"]
            },
            {
                order: "14", color: '#79c2f2', answer: 2, isFinish: false,
                question: "抗肺纖維化藥物經事前審查核准後，每___週需檢送評估資料再次申請?",
                items: ["30週", "22週", "24週", "34週"]
            },
            {
                order: "15", color: '#1884c6', answer: 2, isFinish: false,
                question: "全身性硬化症有關之間質性肺病是ILD(間質性肺病)的一種，請問他的英文縮寫為?",
                items: ["SSD-ILD", "SS-ILD", "SSc-ILD", "SSs-ILD"]
            },
            {
                order: "16", color: '#79c2f2', answer: 0, isFinish: false,
                question: "下列何者是特發性肺纖維化(IPF) 的治療藥物?",
                items: ["OFEV", "抗生素", "GIOTRIF", "SPIOLTO"]
            },
            {
                order: "17", color: '#1884c6', answer: 0, isFinish: false,
                question: "肺活量(FVC)是評估特發性肺纖維化(IPF)嚴重程度的依據之一?",
                items: ["是", "否"]
            },
            {
                order: "18", color: '#79c2f2', answer: 2, isFinish: false,
                question: "下列何者不是抗纖維化藥物(OFEV)的適應症?",
                items: ["特發性肺纖維化(IPF)", "全身性硬化症有關之間質性肺病(SSc-ILD)", "全身性紅斑狼瘡(SLE)", "漸進性纖維化間質性肺病(PF-ILD)"]
            },
            {
                order: "19", color: '#2ca6e0', answer: 0, isFinish: false,
                question: "抗肺纖維化藥物OFEV(Nintedanib)服用的方式?",
                // description: "是一種注射性的蛋白質，可阻斷特定發炎因子，進而有效所小病灶、控制病情、延緩病情。",
                items: ["一天兩次，一次一顆", "一天三次，一次三顆","一天一次，一次一顆"]
            },
            {
                order: "20", color: '#2ca6e0', answer: 2, isFinish: false,
                question: "目前唯一正式取得漸進性纖維化間質性肺病適應症(PF-ILD)的藥物是?",
                items: ["Placebo", "Corticosteroids", "OFEV(Nintedanib)"]
            },
            {
                order: "21", color: '#9ecce8', answer: 1, isFinish: false,
                question: "2種抗肺纖維化藥物(Nintedanib 與 Pirfenidone ) ，可以一起併用?",
                items: ["是", "否"]
            },
            {
                order: "22", color: '#6dadc6', answer: 2, isFinish: false,
                question: "下列何者是診斷PF-ILD的重要影像學檢測?",
                items: ["正子斷層造影(PET)", "超音波檢查(ultrasound)", "高解析度電腦斷層(HRCT)"]
            },
            {
                order: "23", color: '#79c2f2', answer: 0, isFinish: false,
                question: "在接受抗肺纖維化之藥物OFEV(Nintedanib)的治療時，「若有忘記服藥，也不得於下次服藥時同時補上遺漏的劑量」，此敘述是否正確?",
                items: ["是", "否"]
            },
            {
                order: "24", color: '#6dadc6', answer: 0, isFinish: false,
                question: "肺功能出現惡化（經確認病人的用力肺活量預測值降低____%或以上情況發生時），得以續用或得申請使用不同機轉藥物治療並觀察12週，如再測之FVC 未改善應停止使用。",
                items: ["10%", "15%", "20%"]
            },
            {
                order: "25", color: '#2ca6e0', answer: 1, isFinish: false,
                question: "PF-ILD的全名，是否為「 Progression Free Interstitial Lung Disease」。",
                items: ["是", "否"]
            },
        ]
    }
    oncreate(vnode) {

    }

    view() {
        return [
            m(".container", [
                m(".row.no-gutters.w-100.px-5", [
                    m(".col-md-12.py-3", [
                        m(".d-flex.justify-content-between", [
                            m("img.img-fluid", {
                                src: `./img/logo1.png`,
                                maxWidth: "250px",
                                maxHeight: "80px"
                            }),
                            m(".d-flex.align-items-center", [
                                m(".btn.btn-outline-dark", {
                                    style: {
                                        color: "#1884c6",
                                        fontSize: "1.5rem",
                                        textShadow: "1px 0 #1884c6",
                                        borderRadius: "15px"
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.questions.map((_question) => {
                                            _question.isFinish = false
                                        })
                                    }
                                }, "RESTART")
                            ])
                        ])
                    ]),
                ]),
                m(".row.no-gutters.w-100.row-cols-5.px-5", [
                    this.questions.map((_question, index) => {
                        return [
                            m(".col.pt-2.pb-2", {
                                style: {
                                    cursor: "pointer"
                                },
                                class: classNames({
                                    'pr-2': index % 5 == 0,
                                    'px-1': index % 5 == 1 || index % 5 == 2 || index % 5 == 3,
                                    'pl-2': index % 5 == 4,
                                }),
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (!_question.isFinish) {
                                        Dialog.show(QuestionDialog, {
                                            attrs: {
                                                model: _question
                                            },
                                        })
                                    }
                                }
                            }, [
                                m(".p-3.d-flex.justify-content-center.align-items-center.flex-column", {
                                    style: {
                                        background: (_question.isFinish) ? "#eeeeef" : _question.color,
                                        borderRadius: "30px",
                                        minHeight: "14vh"
                                    }
                                }, [
                                    m("span.font-weight-bold", {
                                        style: {
                                            color: (_question.isFinish) ? "#2ca6e0" : "#FFFFFF",
                                            fontSize: "2rem",
                                            fontWeight: 700
                                        }
                                    }, "BINGO"),
                                    m("span.font-weight-bold", {
                                        style: {
                                            textShadow: (_question.isFinish) ? "1px 0 #2ca6e0" : "1px 0 #FFFFFF",
                                            color: (_question.isFinish) ? "#2ca6e0" : "#FFFFFF",
                                            fontSize: "5rem",
                                            lineHeight: "4.25rem",
                                            fontWeight:700
                                        }
                                    }, _question.order),

                                ])
                            ])
                        ]
                    })

                ])

            ]),
            m(Dialog),
        ]
    }
}

export default DashboardPage